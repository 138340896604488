<template>
  <span v-if="duration">{{ Go.formatDuration(duration) }}</span>
</template>

<script>
export default {
  props: ["video"],
  computed: {
    duration: function() {
      return this.video.info && this.video.info.format && this.video.info.format.duration;
    },
  },
};
</script>
